import { jsx } from "react/jsx-runtime";
import { createState } from "@tecnobit-srl/state";
import { useIsSsr } from "@tecnobit-srl/hook-use-is-ssr";
import { find, filter } from "lodash-es";
import { useEffect, useMemo } from "react";
const { ContextProvider, useStore } = createState(
  "Styles",
  (set) => ({
    registeredStyles: [],
    registerStyle: (style, priority = 100) => {
      set((state) => {
        if (!find(state.registeredStyles, (s) => s.name === style)) {
          state.registeredStyles = [
            ...state.registeredStyles,
            {
              name: style,
              priority
            }
          ];
        }
        return { ...state };
      });
      return () => {
        set((state) => {
          state.registeredStyles = filter(
            state.registeredStyles,
            (s) => s.name !== style
          );
          return { ...state };
        });
      };
    }
  })
);
function StylesProvider({ children }) {
  return /* @__PURE__ */ jsx(ContextProvider, { children });
}
function useRegisteredStyles() {
  return useStore((state) => state.registeredStyles);
}
function Style({
  style,
  priority = 100
}) {
  const registerStyle = useStore((state) => state.registerStyle);
  useEffect(
    () => registerStyle(style, priority),
    [style, priority, registerStyle]
  );
  const ssrStyle = useMemo(
    () => ({
      display: "none"
    }),
    []
  );
  const isSsr = useIsSsr();
  if (isSsr) {
    return /* @__PURE__ */ jsx(
      "span",
      {
        className: "ssr-style-elements",
        style: ssrStyle,
        "data-id": style,
        "data-priority": priority
      }
    );
  }
  return null;
}
export {
  Style,
  StylesProvider,
  useRegisteredStyles
};
